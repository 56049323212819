:root {
  --outline-color: black;
}

[data-theme='dark'] {
  --outline-color: white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.word-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 24px;
  font-family: monospace;
}

.word-item {
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  min-width: 100px;
  text-align: center;
}

.word-item input {
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 16px;
}

.word-item input:disabled {
  background-color: #f0f0f0;
  color: #000;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-2px); }
  20%, 40%, 60%, 80% { transform: translateX(2px); }
}

.shake {
  animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes flip {
  0% {
    transform: perspective(400px) rotateX(0);
    background-color: white;
  }
  100% {
    transform: perspective(400px) rotateX(360deg);
  }
}

.flip {
  animation: flipIn 0.6s ease-out forwards;
  transform-origin: center;
}

@keyframes flipIn {
  0% {
    opacity: 0;
    transform: rotateX(180deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

/* Add animation delay classes for each position */
.flip-delay-0 { animation-delay: 0.1s; }
.flip-delay-1 { animation-delay: 0.2s; }
.flip-delay-2 { animation-delay: 0.3s; }
.flip-delay-3 { animation-delay: 0.4s; }
.flip-delay-4 { animation-delay: 0.5s; }
.flip-delay-5 { animation-delay: 0.6s; }
.flip-delay-6 { animation-delay: 0.7s; }

@keyframes correctLetter {
  0% { background-color: white; }
  50% { background-color: #4CAF50; } /* Green flash */
  100% { background-color: #f0f0f0; } /* Final gray state */
}

.correct-letter {
  animation: flip 0.6s ease forwards,
             correctLetter 0.6s ease forwards;
}

input:focus {
  outline: none !important;
}

/* Add these new CSS rules */
.letter-hidden {
  opacity: 0;
  transform: rotateX(180deg);
}

.rules-button {
  position: absolute;
  right: 5px;
  top: 10px;
}

@media (max-width: 768px) {
  .rules-button {
    top: 0;
  }
}

@keyframes fadeUpAndOut {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(-10px);
  }
  80% {
    opacity: 1;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.points-animation {
  animation: fadeUpAndOut 2s ease-out forwards;
}

.letter-hidden::placeholder {
  color: #ECC9D0;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.win-message {
  animation: slideDown 0.5s ease-out forwards;
}

.letter-hidden:focus {
  outline: 2px solid var(--outline-color) !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  40% {
    transform: scale(1.02);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  40% {
    transform: scale(1.02);
    opacity: 0.8;
  }
  100% {
    transform: scale(0.95);
    opacity: 0;
  }
}

.theme-toggle {
  transition: transform 0.2s ease !important;
}

.theme-toggle:hover {
  transform: rotate(45deg) !important;
}

.theme-toggle-icon {
  transition: transform 0.4s ease;
  display: inline-block;
}

.theme-toggle-icon.flipped {
  transform: rotateY(180deg);
}
